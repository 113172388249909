import React from 'react';
import { Link } from 'react-scroll';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="logo-container"><img src="/TALES.png" alt="ロゴ" width={"80px"} height={"80px"}  /><p><strong>Tales Inc.</strong></p></div>
      <header className="App-header">
        <nav>
          <ul> 
            <li><Link to="philosophy" smooth={true} duration={500}><strong>企業理念</strong></Link></li>
            <li><Link to="business" smooth={true} duration={500}><strong>事業紹介</strong></Link></li>
            <li><Link to="news" smooth={true} duration={500}><strong>お知らせ</strong></Link></li>
            <li><Link to="contact" smooth={true} duration={500}><strong>コンタクト</strong></Link></li>
            <li><Link to="company-overview" smooth={true} duration={500}><strong>会社概要</strong></Link></li>
          </ul>
        </nav>
      </header>

      <main>
      <section className="top-image">
          <h1>東京発、国内外を繋ぐ<br />ECとソフトウェアの<br />トータルソリューション</h1>
        </section>
        <section id="philosophy">
          <h2>企業理念</h2>
          <p><strong>私たちは、東京から世界へ広がる最先端のECとソフトウェアソリューションを提供し、企業の成長と成功を支援します。<br />国内外の市場で信頼されるパートナーとしてお客様のニーズに応え、革新的な技術とサービスで新たな価値を創造します。</strong></p>
        </section>
        <section id="business">
          <h2>事業紹介</h2>
          <div className="content">
            <div className="item">
              <img src="/business1.jpg" alt="事業1" />
              <p><strong>輸出業</strong><br />

国産ホビーを中心とした製品を国外に販売しております。
<br />
<strong>【取引地域】</strong>
<br />
北米
東アジア
東南アジア
イギリス
EU諸国
<br />
南米一部地域（チリ、ペルー）</p>
            </div>
            <div className="item">
              <img src="/business2.jpg" alt="事業2" />
              <p><strong>卸小売業</strong><br />フィギュア、カードゲーム、プラモデルといった国産ホビー製品を中心としたEC小売、卸売を行っております。</p>
            </div>
            <div className="item">
              <img src="/business3.jpg" alt="事業3" />
              <p><strong>コンピューターソフトウェア事業</strong><br />主にECビジネスに関するソフトウェアの開発を行っております。</p>
            </div>
            <div className="item">
              <img src="/business4.jpg" alt="事業4" />
              <p><strong>コンサルティング事業</strong><br />主にECサイトの運営、管理についてのコンサルティングを行っております。</p>
            </div>
          </div>
        </section>
        <section id="news">
          <h2>お知らせ</h2>
          <ul>
            <li>
              <p>お知らせはここに掲載します。</p>
            </li>
          </ul>
        </section>
        <section id="contact">
    <h2>コンタクト</h2>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd2Da7Fv1irdaxQUYHSvnNybjYetiLvbsEgpcQgGMaPTO9p3Q/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
</section>

        <section id="company-overview">
          <h2>会社概要</h2>
          <table>
            <tbody>
              <tr>
                <th>商号</th>
                <td>株式会社テイルズ（Tales Inc.）</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>〒113-0023 東京都文京区向丘1-1-17 タカサキヤビル9F</td>
              </tr>
              <tr>
                <th>代表</th>
                <td>渡辺聖司</td>
              </tr>
              <tr>
                <th>E-mail</th>
                <td>seiji.w@talesjp.info</td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td>09037690402</td>
              </tr>
              <tr>
                <th>創業</th>
                <td>2024年5月21日</td>
              </tr>
              <tr>
                <th>資本金</th>
                <td>110万円</td>
              </tr>
              <tr>
                <th>事業内容</th>
                <td>輸出業・卸小売業・コンピューターソフトウェア事業・コンサルティング事業</td>
              </tr>
            </tbody>
          </table>
          <iframe className='map-iframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.406304792425!2d139.75572941187565!3d35.71622482793562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c3392db43b9%3A0xdb8ed51612e32566!2z44CSMTEzLTAwMjMg5p2x5Lqs6YO95paH5Lqs5Yy65ZCR5LiY77yR5LiB55uu77yR4oiS77yR77yXIOOCv-OCq-OCteOCreODpOODk-ODqw!5e0!3m2!1sja!2sjp!4v1718561260725!5m2!1sja!2sjp"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>
      </main>
    </div>
  );
}

export default App;
